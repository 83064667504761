import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';




const AdminNav = () => {
    const [user] = useAuthState(auth);
    const handleSignout = () => {
        signOut(auth);
    };
   
    return (

        <div className='container d-flex justify-content-center'>
            <div className='single-card'>
                    <Link to='/'> <p>Admin</p></Link>
                </div>
           {
            user ?  <div className='single-card'>
            <Link className="" onClick={handleSignout}>Signout</Link>
            </div> :
            <></>
           }
        </div>
    );
};

export default AdminNav;